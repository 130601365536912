<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva sucursal
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar sucursal" single-inline hide-details
                        @keydown.enter="searchSucursal" placeholder="Buscar por nombre, clave o direccion ">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="sucursales" class="elevation-1" fixed-header color="primary"
                :loading="loading" :options.sync="options" :server-items-length="totalSucursal"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }">
                    <!-- <template v-slot:top>
                        <v-toolbar flat>
                            <v-btn small dark color="primary" @click="dialog = true">
                                <v-icon left>fas fa-plus-square</v-icon>Nueva sucursal
                            </v-btn>
                            <v-toolbar-title>Listado de sucursales</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template> -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editSucursal(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="removeSucursal(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip small :color="colorstatus(item.activo)" label x-small dark
                        v-text="item.activo ? 'Activo' : 'Inactivo'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <ModalNuevaSucursal :dialog.sync="dialog" :initialize.sync="initTableSucursal"
        :empresas.sync="empresas" v-if="dialog"/>
        <ModalEditarSucursal :dialog.sync="dialogEdit" :sucursal.sync="dataEditSucursal"
        :initialize.sync="initTableSucursal" :empresas.sync="empresas" v-if="dialogEdit"
        :handleModal="handleEditModal"/>
    </v-row>
</template>
<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EmpresaDataService from '@/service/catalogo/empresa/empresaDataService';
import ModalNuevaSucursal from './NuevaSucursal.vue';
import ModalEditarSucursal from './EditarSucursal.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
export default {
    name: 'ListaSucursal',
    components: {
        ModalNuevaSucursal, ModalEditarSucursal, Columns
    },
    created() {
        this.initTableSucursal();
        this.getEmpresa();
    },
    data() {
        return {
            empresas: [],
            sucursales: [],
            options: {},
            totalSucursal: 0,
            search: '',
            loading: false,
            dialog: false,
            dialogEdit: false,
            dataEditSucursal: {},
            headers:[
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', align: " d-none", value: 'id', visible: false, sortable: false, groupable: false, show: false },
                { text: 'Nombre sucursal', value: 'nombre', sortable: false, groupable: false, show: true },
                { text: 'Dirección', value: 'direccion', sortable: false, groupable: false, show: true },
                { text: 'Telefono', value: 'phoneNumber', sortable: false, groupable: false, show: true },
                { text: 'Clave', value: 'clave', sortable: false, groupable: false, show: true },
                { text: 'Empresa', value: 'empresa.razonSocial', sortable: false, groupable: false, show: true },
                { text: 'Activo (g)', value: 'activo', sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods:{
        async getEmpresa(){
            let { data } = await EmpresaDataService.lstEmpresa();
            this.empresas = data;
        },
        searchSucursal(){
            this.options.page = 1;
            this.initTableSucursal( this.options );
        },
        initTableSucursal( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            SucursalDataService.getallSucursal( params ).then( response => {
                let { rows, totalElements } = response.data;
                this.sucursales = rows;
                this.totalSucursal = totalElements;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        editSucursal( item ){
            SucursalDataService.show( item.id ).then( response => {
                this.dataEditSucursal = response.data;
                this.dialogEdit = true;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salió mal!' + err.response.data, footer: 'Intenta de nuevo', timer: 3500 });
            });
        },
        removeSucursal( item ){
            this.$swal.fire({
                title: '¿Estás seguro?',
                text: '¡No podrás revertir esto!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    SucursalDataService.delete( item.id ).then( resp => {
                        if( resp.status == 200 ){
                            this.initTableSucursal();
                            this.$swal.fire({ icon: 'success', title: '¡Hecho!', text: 'Se eliminó correctamente', footer: 'Sucursa desactivada', timer: 3500 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salió mal!' + err.response.data, footer: 'Intenta de nuevo' });
                    });
                }
            });
        },
        colorstatus( status ){ return status ? 'green' : 'red'; },
        handleEditModal(){ this.dialogEdit = !this.dialogEdit; }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableSucursal(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter(h => h.show); },
    }
}
</script>