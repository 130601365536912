<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsSucursal" v-slot="{invalid, validated}">
                    <v-form name="form" @submit.prevent="saveSucursal">
                        <v-toolbar tile dark color="primary">
                            <v-btn icon dark @click.native="close">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Nueva sucursal</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar sucursal</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                 <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id"
                                        label="Empresa*" v-model="sucursal.idEmpresa" required clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <!-- <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required|max:10" v-slot="{ errors, valid }">
                                        <v-text-field label="Clave sucursal" v-model="sucursal.clave" prepend-icon="fas fa-keyboard"
                                        required :error-messages="errors" :success="valid" counter>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col> -->
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="Nombre del sucursal*" v-model="sucursal.nombre" prepend-icon="fas fa-lock"
                                        :error-messages="errors" :success="valid" required clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min:10|max:13" v-slot="{ errors, valid }">
                                        <v-text-field type="tel" label="Telefono*" prepend-icon="fas fa-phone"
                                        v-model="sucursal.phoneNumber" :counter="13" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="8">
                                    <v-text-field label="Dirección" v-model="sucursal.direccion" hint="Direccion de la sucursal" 
                                    prepend-icon="fas fa-map-marker-alt" persistent-hint clearable>
                                    </v-text-field>
                                </v-col>
                                 <v-col cols="12" sm="6" md="4">
                                    <v-checkbox  class="mx-2" v-model="sucursal.activo" :hint="(sucursal.activo == false ? 'Habilitar ' : 'Desactivar ') +'sucursal en el sistema'"
                                    persistent-hint :label="sucursal.activo == true ? 'Sucursal Activa': 'Sucursal inactiva'"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
export default {
    name: 'NuevaSucursal',
    props: {
        dialog: { type: Boolean, default: false },
        initialize: { type: Function },
        empresas: { type: Array, default: () => [] }
    },
    data() {
        return {
            sucursal: {
                id:         null,
                idEmpresa:  null,
                clave:      "",
                nombre:     "",
                direccion:  "",
                phoneNumber: "",
                activo:     true,
                eliminado:  false,
            }
        }
    },
    methods: {
        close(){
            this.$emit('update:dialog', false)
        },
        resetData(){
            this.sucursal = { id: null, idEmpresa: null, clave: "", nombre: "", direccion: "", activo: true, eliminado: false }
            this.$emit('update:dialog', false);
            this.$refs.obsSucursal.reset();
        
        },
        saveSucursal(){
            this.$refs.obsSucursal.validate().then( isValid => {
                if( isValid ){
                    SucursalDataService.create( this.sucursal ).then( rsp => {
                        if (rsp.status === 200 || rsp.status === 201) {
                            this.resetData();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Datos registrados', text: rsp.data.message, showConfirmButton: false, timer: 4000 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
                    })
                }
            }).catch( err  => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'No se pudo guardar los datos, ' +err.message, showConfirmButton: false, timer: 4000 });
            })
        }
    },
}
</script>